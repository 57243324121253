import React, {useContext, useState} from 'react';

const LogsContext = React.createContext({
  logs: [],
  msg: () => {},
  warn: () => {},
  error: () => {},
  clear: () => {}
});

const useLogs = () => useContext(LogsContext);

const LogsContextProvider = props => {

  const [logs, setLogs] = useState([]);

  const msg = (text, ...args) => {
    addLog('msg', text, ...args);
    console.log(text, ...args);
  };

  const warn = (text, ...args) => {
    addLog('warn', text, ...args);
    console.warn(text, ...args);
  };

  const error = (text, ...args) => {
    addLog('error', text, ...args);
    console.error(text, ...args);
  };

  const addLog = (type, text, ...args) => {
    const log = {
      type,
      timestamp: new Date(),
      text,
      data: args
    }
    setLogs(prev => [...prev, log]);
  }

  const clear = () => {
    setLogs([]);
  }

  return (
    <LogsContext.Provider value={{ logs, msg, warn, error, clear }}>
      {props.children}
    </LogsContext.Provider>
  )
};

export { LogsContextProvider, useLogs };