import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { CtrlsContextProvider } from './contexts/CtrlsContext';
import { LogsContextProvider } from './contexts/LogsContext';
import { CfgContextProvider } from './contexts/CfgContext';

ReactDOM.render(
  <React.StrictMode>
    <LogsContextProvider>
      <CtrlsContextProvider>
        <CfgContextProvider>
          <App />
        </CfgContextProvider>
      </CtrlsContextProvider>
    </LogsContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

