import React, {useContext, useState} from 'react';

const CtrlsContext = React.createContext({
  test: false,
  config: true,
  cmdTest: () => {},
  cmdConfig: () => {},

  logs: false,
  showLogs: () => {},

  internalGeolocation: false,
  cmdInternalGeolocation: () => {},

  center: true,
  cmdCenter: () => {},
  cmdCenterDone: () => {},

  restart: true,
  cmdRestart: () => {},
  cmdRestartDone: () => {}
});

const useCtrls = () => useContext(CtrlsContext);

const CtrlsContextProvider = props => {

  const [test, setTest] = useState(false);
  const [config, setConfig] = useState(true);
  const [internalGeolocation, setInternalGeolocation] = useState(false);
  const [center, setCenter] = useState(true);
  const [restart, setRestart] = useState(false);
  const [logs, setLogs] = useState(false);

  const cmdTest = () => {
    setTest(true);
    setConfig(false);
  };

  const cmdConfig = () => {
    setTest(false);
    setConfig(true);
  };

  const showLogs = () => {
    logs ? setLogs(false) : setLogs(true);
  }

  const cmdInternalGeolocation = () => {
    internalGeolocation ? setInternalGeolocation(false) : setInternalGeolocation(true);
  }

  const cmdCenter = () => {
    setCenter(true);
  }

  const cmdCenterDone = () => {
    setCenter(false);
  }

  const cmdRestart = () => {
    setRestart(true);
  }

  const cmdRestartDone = () => {
    setRestart(false);
  }

  return (
    <CtrlsContext.Provider 
    value={{
      test, config, cmdTest, cmdConfig, 
      logs, showLogs,
      internalGeolocation, cmdInternalGeolocation, 
      center, cmdCenter, cmdCenterDone,
      restart, cmdRestart, cmdRestartDone
      }}>
      {props.children}
    </CtrlsContext.Provider>
  )
};

export { CtrlsContextProvider, useCtrls };