import { useCtrls } from '../contexts/CtrlsContext';
import { BiTestTube, BiCurrentLocation, BiRefresh } from 'react-icons/bi';
import { BsGearFill, BsList, BsTrashFill, BsCloudUpload, BsCloudDownload } from 'react-icons/bs';
import { MdFilterCenterFocus } from 'react-icons/md';
import { useLogs } from '../contexts/LogsContext';
import { useCfg } from '../contexts/CfgContext';

const CtrlPanel = () => {

  const ctrls = useCtrls();
  const logs = useLogs();
  const cfg = useCfg();

  return (
    <div className="h-screen flex flex-col pt-4 pb-4 box-border items-center justify-between w-20 bg-secondary">

      {/* test */}
      { cfg.cfg ? <div className="flex flex-col items-center">
        <SideBarIcon icon={<BiTestTube size="28"/>} desc="Test" selected={ctrls.test} onClick={ctrls.cmdTest} />
        { ctrls.test ? <SideBarIcon small={true} icon={<BiCurrentLocation size="14"/>} desc="GPS"
          selected={ctrls.internalGeolocation} onClick={ctrls.cmdInternalGeolocation} /> : null }
        { ctrls.test ? <SideBarIcon small={true} icon={<BiRefresh size="14"/>} desc="Restart"
          onClick={ctrls.cmdRestart} /> : null }
      </div> : null }
      
      {/* config */}
      <div className="flex flex-col items-center">
        <SideBarIcon icon={<BsGearFill size="24"/>} desc="Config" selected={ctrls.config} onClick={ctrls.cmdConfig} />
        { ctrls.config ? <SideBarIconUpload small={true} icon={<BsCloudUpload size="14"/>} desc="Upload"
          onClick={cfg.cfgUpload} /> : null }
        { ctrls.config && cfg.cfg ? <SideBarIcon small={true} icon={<BsCloudDownload size="14"/>} desc="Download"
          onClick={cfg.cfgDownload} /> : null }
      </div>

      {/* center map */}
      { cfg.cfg ? <SideBarIcon icon={<MdFilterCenterFocus size="28"/>} desc="Center Map"
      onClick={ctrls.cmdCenter} /> : null }

      {/* logs */}
      <div className="flex flex-col">
        <SideBarIcon icon={<BsList size="24"/>} desc="Logs" selected={ctrls.logs} onClick={ctrls.showLogs} />
        { ctrls.logs ? <SideBarIcon small={true} icon={<BsTrashFill size="14"/>} desc="Clear"
          onClick={logs.clear} /> : null }
      </div>
    </div>
  )
}

const SideBarIcon = ({icon, desc, selected, small, onClick}) => {

  return (<div className={`sidebar-icon cursor-pointer ${small ? 'small' : null}`} onClick={onClick}>
    <div className={`icon ${selected ? 'selected' : null}`}>{icon}</div>
    <div className={`text ${selected ? 'selected' : null}`}>{desc}</div>
  </div>)
}

const SideBarIconUpload = ({icon, desc, selected, small, onClick}) => {

  return (<div className={`sidebar-icon overflow-hidden relative ${small ? 'small' : null}`}>
    <div className={`icon ${selected ? 'selected' : null}`}>{icon}</div>
    <div className={`text ${selected ? 'selected' : null}`}>{desc}</div>
    <input type="file" accept=".json" className="invisible-file-input" onClick={e => {e.target.value = '';}} onChange={(e) => {onClick(e.target.files[0])}} />
  </div>)
}

export default CtrlPanel;