import { useEffect, useState } from 'react';

import GeoXp from '@mezzo-forte/geoxp';
import CtrlPanel from './components/CtrlPanel';
import Map from './components/Map';
import LogsOverlay from './components/LogsOverlay';
import LogsPanel from './components/LogsPanel';
import { useCtrls } from './contexts/CtrlsContext';
import { useLogs } from './contexts/LogsContext';
import { useCfg } from './contexts/CfgContext';

let geoXp;

const App = () => {

  const [location, setLocation] = useState();

  const ctrls = useCtrls();
  const logs = useLogs();
  const cfg = useCfg();

  useEffect( () => {
    if (cfg.cfg) {
      if (ctrls.test) {
        start();
      } else {
        stop();
      }
    }
  }, [ctrls.test]);

  useEffect( () => {
    if (geoXp) {
      geoXp.internalGeolocation(ctrls.internalGeolocation);
      logs.msg('GeoXp internal geolocation', ctrls.internalGeolocation);
    }
  }, [ctrls.internalGeolocation]);

  useEffect( () => {
    if (ctrls.restart && cfg.cfg) {
      stop();
      setLocation(null);
      start();
      ctrls.cmdRestartDone();
    }
  }, [ctrls.restart]);

  const start = () => {
    // geoXp instance management
    if (geoXp) geoXp.reload(cfg.cfg);
    else geoXp = new GeoXp(cfg.cfg);

    window.geoXp = geoXp;

    geoXp.internalGeolocation(ctrls.internalGeolocation);

    // geoXp events subscription
    geoXp.on('active', spotData => {
      logs.msg('[GEOXP EVENT] - Spot active', spotData.id, spotData.label);
    });
  
    geoXp.on('outgoing', spotData => {
      logs.msg('[GEOXP EVENT] - Spot outgoing', spotData.id, spotData.label);
    });
  
    geoXp.on('visited', spotData => {
      logs.msg('[GEOXP EVENT] - Spot visited', spotData.id, spotData.label);
    });
  
    geoXp.on('play', audioData => {
      logs.msg('[GEOXP EVENT] - Play audio', audioData.id, audioData.spot.id, audioData.spot.label);
    });
  
    geoXp.on('stop', audioData => {
      logs.msg('[GEOXP EVENT] - Stop audio', audioData.id, audioData.spot.id, audioData.spot.label);
    });

    geoXp.on('position', positionData => {
      const location = {
        lon: positionData.coords.longitude,
        lat: positionData.coords.latitude
      };
      setLocation(location);
    });
    logs.msg('GeoXp started');
  }

  const stop = () => {
    if (geoXp) {
      geoXp.clearCookies();
      geoXp.destroy();
      geoXp = null;
    }
    logs.msg('GeoXp stopped');
  }

  const updateGeolocation = (location) => {
    if (geoXp) {
      geoXp.updateGeolocation({
        coords: {
          latitude: location.lat,
          longitude: location.lng,
          accuracy: 1,
        },
      });
    }
  }
  
  return (
    <div className="flex h-screen w-screen relative overflow-hidden">
      <div className="relative w-full h-full">
        <Map configProp={cfg.cfg?.geo} locationProp={location} 
        onLocationSelected={ (location) => updateGeolocation(location)}
        reset={!ctrls.test || ctrls.restart}/>
        { ctrls.logs ? <LogsPanel /> : null }
        <LogsOverlay />
      </div>
      <CtrlPanel />
    </div> 
  );
}

export default App