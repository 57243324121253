import React, {useContext, useState} from 'react';
import LogsOverlay from '../components/LogsOverlay';
import { useCtrls } from './CtrlsContext';
import { useLogs } from './LogsContext';

let storedCfg = null;

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const CfgContext = React.createContext({
  cfg: null,
  cfgUpload: () => {},
  cfgDownload: () => {}
});

const useCfg = () => useContext(CfgContext);

const CfgContextProvider = props => {

  const log = useLogs();
  const ctrls = useCtrls();

  const [cfg, setCfg] = useState();

  const cfgUpload = (file) => {

    log.msg('Uploading new config...');
    const reader = new FileReader();
    reader.onload = handleReader;
    reader.readAsText(file);
  }

  const handleReader = (e) => {
    try {
      const config = JSON.parse(e.target.result);

      log.msg('Config validation...');
      const validCfg = validate(config);
      if (validCfg) {
        log.msg('Config is ok');

        // saving config for later
        storedCfg = JSON.parse(JSON.stringify(validCfg));

        // substitute content with fake one
        // validCfg.audio.sounds.forEach(sound => {
        //   sound.url = `./audio/${getRandomInt(1, 4)}.mp3`;
        // })

        // setting new cfg
        setCfg(validCfg);

        // recenter map
        ctrls.cmdCenter();

        log.msg('Config loaded');
      }
    } catch (ex) {
      log.error('Error parsing JSON file', ex);
    }
  }

  const cfgDownload = () => {
    log.msg('Dowloading config...');

    // TODO save the actual modified config
    if (!storedCfg) {
      log.warn('Nothing to download');
      return;
    }

    const toDowload = storedCfg;

    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([JSON.stringify(toDowload, null, 2)], {
      type: "json"
    }));
    a.setAttribute("download", "geoXpConfig.json");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const validate = (config) => {

    // geo config
    if (!config.geo) {
      log.error('Missing "geo" object');
      return null;
    }
    if (!config.geo.positions && !Array.isArray(config.geo.positions)) {
      log.error('Error in "geo.position" array');
      return null;
    }
    config.geo.positions.forEach((pos, idx) => {
      if (!pos.id || !pos.lon || !pos.lat) {
        log.error(`Missing info in "geo.positions[${idx}]"`, pos);
        return null;
      }
    })

    // audio config
    if (!config.audio) {
      log.error('Missing "audio" object');
      return null;
    }
    if (!config.audio.sounds && !Array.isArray(config.audio.sounds)) {
      log.error('Error in "audio.sounds" array');
      return null;
    }
    config.audio.sounds.forEach((sound, idx) => {
      if (!sound.id || !sound.url) {
        log.error(`Missing info in "audio.sound[${idx}]"`, sound);
        return null;
      }
    })

    // experience config
    if (!config.experience) {
      log.error('Missing "experience" object');
      return null;
    }
    if (!config.experience.patterns && !Array.isArray(config.experience.patterns)) {
      log.error('Error in "experience.patterns" array');
      return null;
    }
    config.experience.patterns.forEach((pattern, patternIdx) => {
      if (!pattern.id || !pattern.spots || !Array.isArray(pattern.spots)) {
        log.error(`Missing info in "experience.pattern[${patternIdx}]"`, pattern);
        return null;
      }
      pattern.spots.forEach((spot, spotIdx) => {
        if (!spot.id || !spot.audio || !spot.position) {
          log.error(`Missing info in "experience.pattern[${patternIdx}].spots[${spotIdx}]"`, spot);
          return null;
        }
      })
    })

    // everything is ok
    return config;
  }

  return (
    <CfgContext.Provider
    value={{
      cfg,
      cfgUpload, cfgDownload
      }}>
      {props.children}
    </CfgContext.Provider>
  )
};

export { CfgContextProvider, useCfg };