import { useEffect, useState } from "react";
import { useLogs } from "../contexts/LogsContext"
import Log from "./Log";

const LogsPanel = () => {

  const logs = useLogs();

  const [logsList, setLogsList] = useState([]);

  useEffect(() => {
    if (logs && logs.logs) {
      setLogsList(logs.logs);
    }
  }, [logs.logs])

  return (
    <div className="absolute pt-2 pl-2 top-0 left-0 box-border overflow-y-scroll h-full w-full bg-primary z-20">
      {logsList?.map((log, idx) => {
        return <Log key={idx} log={log} overlay={false}/>
      })}
    </div>
  )
}

export default LogsPanel;