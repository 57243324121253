const howlPropsToStringify = [
  '_src',
  '_duration',
  '_state'
];

const isObject = (val) => {
  return (
    typeof val === 'object' &&
    !Array.isArray(val) &&
    val !== null
  );
}

const ParseData = ({data}) => {
  let output = [];

  if (data && Array.isArray(data) && data.length > 0) {
    data.forEach(data => {
      const dataToPrint = isObject(data) ? { ...data } : data;

      if (dataToPrint && dataToPrint.audio && isObject(dataToPrint.audio)) {
        dataToPrint.audio = JSON.parse(JSON.stringify(dataToPrint.audio, howlPropsToStringify));
      }

      output.push(JSON.stringify(dataToPrint));
    });
  }

  return (
    <span>{ output.length > 0 ? 
      output.map((arg, idx) => {
        return <span key={idx}>{`[${arg}]`}</span>
      })
    : null}</span>
  )
}

const Log = ({log, overlay}) => {

  let color = 'text-blue';

  switch (log.type) {
    case 'msg': color = 'text-blue'; break;
    case 'warn': color = 'text-yellow'; break;
    case 'error': color = 'text-red'; break;
    default: color = 'text-blue'; break;
  }

  return (
    <div className={`w-full max-w-full mb-1 text-sm break-all ${overlay ? 'animate-fade' : null}`}>
      {log ?
      <div className={`flex flex-row ${color}`}>
        {!overlay ? <div className="flex-none">{`[${log.timestamp?.toLocaleTimeString()}]`}</div> : null} 
        <div>
          <span className="ml-1 mr-1">{log.text}</span> 
          <ParseData data={log.data} />
        </div>
      </div> : null }
    </div>
  )
}

export default Log;