import 'leaflet/dist/leaflet.css';

import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, useMap, useMapEvent, Circle, Tooltip, CircleMarker } from 'react-leaflet'
import * as L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { useCtrls } from '../contexts/CtrlsContext';

const DEFAULT_ZOOM = 5;

const DEFAULT_POS = L.latLng([45.071104, 7.670225]);

const routeOpts = { color: 'red', opacity: 0.5 };
const markerOpts = { color: 'red', fillOpacity: 0.5, opacity: 0.5, weight: 1 };
const radiusOpts = { color: 'black', fillOpacity: 0.3, opacity: 0.5, weight: 1 };
const deadbandOpts = { color: 'black', fillOpacity: 0.1, opacity: 0.3, weight: 1 };

const Map = ({ locationProp, configProp, reset, onLocationSelected }) => {

  const ctrls = useCtrls();

  const [positions, setPositions] = useState([]);
  const [location, setLocation] = useState();
  const [route, setRoute] = useState([]);
  const [bounded, setBounded] = useState(false);

  L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

  // ceneter
  useEffect(() => {
    if (ctrls.center) {
      setBounded(false);
      ctrls.cmdCenterDone();
    }
  }, [ctrls.center]);

  // reset
  useEffect(() => {

    if (reset) {
      setRoute([]);
      setLocation(null);
    }
  }, [reset]);

  // current location
  useEffect(() => {

    if (locationProp) {
      setRoute(prev => [...prev, L.latLng(locationProp.lat, locationProp.lon)]);
      setLocation(locationProp);
    }
  }, [locationProp]);

  // configured positions
  useEffect(() => {

    if (configProp && configProp.positions) {
      configProp.positions.forEach(pos => {
        if (!pos.radius) pos.radius = configProp.options.defaultRadius;
        if (!pos.deadband) pos.deadband = configProp.options.defaultDeadband;
      })
      setPositions(configProp.positions);
    }
  }, [configProp]);

  /*
  React component
  */
  return (
    <div className="h-full w-full">
      <MapContainer
        id="map"
        center={DEFAULT_POS}
        zoom={DEFAULT_ZOOM}
        scrollWheelZoom={false}
        gestureHandling={true}
        className="h-full w-full z-0"
      >

        {/* tiles */}
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {/* bounds */}
        {!bounded ? <Bounds positions={positions} onBounded={() => setBounded(true)} /> : null}

        {/* config positions deadbands*/}
        {positions && positions.length > 0 ?
          <div>
            {positions.map((pos, idx) => {
              return <div key={idx}>
                <Circle center={L.latLng(pos.lat, pos.lon)}
                  radius={pos.radius + pos.deadband}
                  pathOptions={deadbandOpts} />
              </div>
            })}
          </div>
          : null
        }

        {/* config positions radiuses*/}
        {positions && positions.length > 0 ?
          <div>
            {positions.map((pos, idx) => {
              return <div key={idx}>
                <Circle center={L.latLng(pos.lat, pos.lon)}
                  radius={pos.radius}
                  pathOptions={radiusOpts}>
                  <Tooltip className="map-tooltip">
                    <strong>{pos.id}</strong><br />
                    {pos.label}
                  </Tooltip>
                </Circle>
              </div>
            })}
          </div>
          : null
        }

        {/* current location */}
        {location ?
          <CircleMarker center={L.latLng(location.lat, location.lon)}
            radius={5} pathOptions={markerOpts} />
          : null}
        <SetLocation onLocation={(location) => onLocationSelected(location)} />

        <Polyline pathOptions={routeOpts} positions={route} />
      </MapContainer>
    </div>
  );
}

const Bounds = ({ positions, onBounded }) => {

  const map = useMap();

  if (positions && Array.isArray(positions) && positions.length > 0) {
    const markerBounds = L.latLngBounds([]);
    positions.forEach(pos => {
      markerBounds.extend(L.latLng(pos.lat, pos.lon));
    })
    markerBounds.isValid() && map.fitBounds(markerBounds, { padding: [50, 50] });
    onBounded();
  }

  return null;
}

const SetLocation = ({ onLocation }) => {
  useMapEvent('click', (e) => {
    onLocation(e.latlng);
  });

  return null;
}

export default Map;